import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../css/SearchResults.css';
import FieldResult from './FieldResult';
import { SearchBar } from './SearchBar';
import { getFields } from '../utilities/getFields';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from '../utilities/firebase';


function SearchResults() {
  const [searchParams] = useSearchParams();
  const sQuery = searchParams.get("q");
  const [searchTerm, setSearchTerm] = useState(sQuery);

  const [fieldsSnapshot, setFieldsSnapshot] = useState();
  const [fieldsLoading, setFieldsLoading] = useState(true);
  const [fieldsError, setFieldsError] = useState();

  useEffect(() => {
    getFields(searchTerm, setFieldsSnapshot, setFieldsError, setFieldsLoading);

    const analytics = getAnalytics(firebaseApp);
      logEvent(analytics, 'view_search_results', {
        search_term: searchTerm,
      });
      console.log(`Logged search event: ${searchTerm}`);
  }, [searchTerm])

  if (fieldsError !== undefined) {
    return <div className="container mt-5">
      <p>There was a problem fetching your results. If refreshing does not solve the problem, please contact <a href="mailto:support@fieldfactsbaseball.com">support</a>.</p>
      <p><a href="/">Go Back</a></p>
    </div>
  }

  return (
   <div className="container-lg">
    <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} />

    <div className="search-count">
      {fieldsLoading && <p>Searching...</p>}
      {fieldsSnapshot?.docs !== undefined && <p>Your search for <b>{searchTerm}</b> returned <b>{fieldsSnapshot.docs.length}</b> results.</p>}
      {fieldsSnapshot?.docs?.length === 0 && <div className="troubleshooting">
        <p>If you are having trouble finding a field, try searching for the name of the city or the zip code.</p>
        <p>Here are some examples of valid searches:</p>
        <ul>
          <li>Terryville High School</li>
          <li>Little River</li>
          <li>44141</li>
          <li>OH</li>
        </ul>
        </div>}
    </div>

    <div className="fields">

      {(fieldsSnapshot?.docs !== undefined && fieldsLoading === false) && fieldsSnapshot.docs.map(f => 
        <FieldResult key={f.id} fieldSnapshot={f} />
      )}
      
    </div>

   </div>
  );
}

export default SearchResults;
