import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ShowField from './components/ShowField';
import SearchResults from './components/SearchResults';
import 'bootstrap/dist/css/bootstrap.css';
import { NotFound } from './components/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
        <Routes>
          <Route path="/" element={<App/>} />

          <Route path="/search" element={
            <SearchResults />} 
            />

          <Route path="/fields/:fieldID" element={<ShowField />} />

            <Route path="*" element={<NotFound />}/>
        </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
