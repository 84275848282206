import '../css/App.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { initializeFirebase } from '../utilities/firebase';
import { enforceCacheReset } from './CacheReset';

initializeFirebase();
enforceCacheReset();

function App() {
  const navigate = useNavigate();

  const shouldUseLargePlaceholderText = (width) => {
    if (width > 523 && !(width > 760 && width < 993)) {
      return true;
    }
    return false;
  }
  
  const [searchTerm, setSearchTerm] = useState();
  const { width } = useWindowDimensions();
  const searchPlaceholder = shouldUseLargePlaceholderText(width) ? "Search for field name, address, state, or zip code" : "Search for a field";

  const startSearch = () =>
    {
      if (searchTerm !== undefined && searchTerm.trim() !== "") {
        navigate({
          pathname: '/search',
          search: createSearchParams({q: searchTerm}).toString()
        }
        );
      } else {
        alert("You need to enter a field name, address, state, or zip code to search for a field.")
      }
    }

  return (
    <header className="masthead">
      <img className="logo" alt="Field Facts: Baseball" src="logo-white.svg" />
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-12 text-center hero">
            <h1>Get All The Facts. <br/>On All The Fields.<br/>All The Time.</h1>
            <p className="lead">Field Facts: Baseball has the most comprehensive database of baseball fields on the internet. Know all of the details about a baseball field before you go. </p>

            <div className="row height d-flex justify-content-center align-items-center">
              <div className="col-md-8">
                <div className="search">
                  <i className="fa fa-search"></i>
                  <form>
                    <input type="text" className="form-control" placeholder={searchPlaceholder} onChange={(e) => {
                      setSearchTerm(e.target.value)
                    }} />
                    <button type="submit" onClick={startSearch} className="btn btn-primary search-btn"><img alt="Search" src="search.svg" /></button>
                  </form>
                </div>
              </div>
            </div>
            <a href="https://apps.apple.com/us/app/field-facts-baseball/id1352780085"><img className="download-ios" alt="Download Field Facts: Baseball on the iOS App Store" src="/download-ios.png" /> </a>

          </div>
        </div>
      </div>
    </header>
  );
}

export default App;
