import { useNavigate } from 'react-router-dom';
import '../css/FieldResult.css';

const imageForField = (field) => {
    let image = undefined;

    if (field.photos && field.photos.length > 0) {
        return field.photos[0].url;
    }

    return image;
}

function FieldResult({fieldSnapshot}) {
    const field = fieldSnapshot.data;
    const image = imageForField(field);
    const navigate = useNavigate();

    return (
    <div key={fieldSnapshot.id}>
        <div className="field">
            <button type="button" onClick={e => {
                navigate( `/fields/${fieldSnapshot.id}`)
            }}>
                {image && <img alt={field.group_name} className="field-image" src={image} onLoad={e => e.target.classList.add('visible')} />}
                {image === undefined && <img alt={field.group_name} className="field-image visible" src="/field-placeholder.jpg" />}
                <div className="field-text-container">
                    <p>{field.group_name}</p>
                </div>
            </button>
        </div>
    </div>
    );
}

export default FieldResult;
