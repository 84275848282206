import {OutfieldQuestions} from '../models/question'
import { FieldItem } from './FieldItem';

export const OutfieldDetails = ({field}) => {
    return (
        <ul>
            {/* {JSON.stringify(field)} */}
            {OutfieldQuestions().map(q => <FieldItem key={q.uuid} field={field} question={q} />)}
        </ul>
    );
}
