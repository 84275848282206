import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../css/PhotoHeader.css";

// import required modules
import { Keyboard, Pagination, Navigation } from "swiper";

export default function PhotoHeader({photoURLs}) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation]}
        className="mySwiper"
      >
        {photoURLs.map(url => <SwiperSlide key={url}><img alt="Field" src={url} /></SwiperSlide> )}
      </Swiper>
    </>
  );
}
