import { useState } from "react";
import '../css/CollapseableArea.css'

export const CollapseableArea = ({title, expandedAreaComponent, initiallyExpanded}) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <>
            <div className="collapseable-area">
                <button onClick={() => {
                    setExpanded(!expanded);
                }}>
                    <p>{title}</p>
                    {expanded && <span>Collapse <img className="rotated" alt="Collapse" src="/chevron-down.svg" /></span>}
                    {expanded || <span>Expand <img alt="Expand" src="/chevron-down.svg" /></span>}
                </button>
            </div>
            {expanded && (
                <div className="collapseable-area-expanded">
                    {expandedAreaComponent}
                </div>
            )}
        </>
    );
}