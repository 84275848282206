import StyledRating from '@mui/material/Rating'

export const IndividualReviewGroup = ({groupName, groupKey, review}) => {
    return (
        <div className="individual-review-group">
            <p>{groupName}</p>
            <StyledRating
                    className="individual-review-group-rating"
                    name="field-rating"
                    defaultValue={review[groupKey]}
                    value={review[groupKey]}
                    precision={0.5}
                    icon={<img alt="Rating Green" src="/rating-green.svg" />}
                    emptyIcon={<img alt="Rating White" src="/rating-white.png" />}
                    readOnly
                />
        </div>
    );
}

export const Review = ({review}) => {
    return (
        <div className="individual-review">
            <hr/>
            <p className="reviewer">{review.reviewer_full_name}</p>
            <IndividualReviewGroup groupName="Overall" groupKey="overall" review={review} />
            <IndividualReviewGroup groupName="Infield" groupKey="infield" review={review} />
            <IndividualReviewGroup groupName="Outfield" groupKey="outfield" review={review} />
            <IndividualReviewGroup groupName="Surrounding Area" groupKey="surrounding" review={review} />
            <p className="review-content">{review.text}</p>
        </div>
    )
}