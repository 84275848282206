export const enforceCacheReset = () => {
    var hours = 24;
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        console.log("Setting a setup time for the cache reset to ", now)
        localStorage.setItem('setupTime', now)
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
            localStorage.setItem('setupTime', now);
            console.log("Cache is stale. Clearing now...")
        } else {
            console.log("Cache is fresh.")
        }
    }
}