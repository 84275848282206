import {SurroundingAreaQuestions} from '../models/question'
import { FieldItem } from './FieldItem';

export const SurroundingAreaDetails = ({field}) => {
    return (
        <ul>
            {/* {JSON.stringify(field)} */}
            {SurroundingAreaQuestions().map(q => <FieldItem key={q.uuid} field={field} question={q} />)}
        </ul>
    );
}
