import { useState } from "react";
import PropTypes from 'prop-types';
import { createSearchParams, useNavigate } from "react-router-dom";

export const SearchBar = ({setSearchTerm, searchTerm, navigateOnChange}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);
    const navigate = useNavigate();

    const startSearch = (nSearchTerm) =>
    {
      if (nSearchTerm !== undefined && nSearchTerm.trim() !== "") {
        navigate({
          pathname: '/search',
          search: createSearchParams({q: nSearchTerm}).toString()
        }
        );
      } else {
        alert("You need to enter a field name, address, state, or zip code to search for a field.")
      }
    }

    return <nav className="navbar search-nav-bar navbar-expand-md navbar-light">
    <div className="container-fluid">
      <a className="navbar-brand" href="/">
        <img className="search-logo" alt="Field Facts: Baseball" src="/logo-green.png" width="160" />
        </a>
      <button className="navbar-toggler" onClick={e => {setShowMenu(!showMenu)}} type="button" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${showMenu ? "show" : ""}`}>
        <ul className="navbar-nav me-auto mb-2 mb-md-0">
        </ul>
        <div className="search-result-search">
          <i className="fa fa-search"></i>
          <form onSubmit={e => {
            setSearchTerm(newSearchTerm);
            e.preventDefault();
          }}>
            <input type="text" className="form-control" defaultValue={searchTerm} onChange={(e) => {
              setNewSearchTerm(e.target.value)
            }} />
            <button type="submit" onClick={() => {
              console.log("Searching again...")
              setSearchTerm(newSearchTerm);

              if (navigateOnChange) {
                startSearch(newSearchTerm);
              }

              window.history.replaceState(null, "Field Facts: Baseball", `/search?q=${newSearchTerm}`)
            }} className="btn btn-primary search-btn"><img alt="Search" src="/search.svg" /></button>
          </form>
        </div>
      </div>
    </div>
  </nav>
}

SearchBar.propTypes = {
  setSearchTerm: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  navigateOnChange: PropTypes.bool,
}

SearchBar.defaultProps = {
  navigateOnChange: false,
  searchTerm: undefined
}