import '../css/ShowField.css';
import { useParams } from "react-router-dom";
import PhotoHeader from './PhotoHeader';
import { SearchBar } from './SearchBar';
import { useEffect, useState } from 'react';
import { useDocument } from "react-firebase-hooks/firestore";
import { collection, doc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { firebaseApp } from '../utilities/firebase';
import { BallTriangle } from "react-loader-spinner";
import { Field } from './Field';
import { getAnalytics, logEvent } from "firebase/analytics";

// http://localhost:3000/fields/37TmKjA3i5wnzYaRxBEg

// Multiple fields, tons of images
// http://localhost:3000/fields/CCBE5DQblsmRwq0OmGPI

// No images:
// http://localhost:3000/fields/pE90d1a7BSuiFoGCXTW2

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


const photosForFieldSnapshots = (fieldSnapshots) => {
  const maxFieldPhotosToShow = 10;
  const photos = [];
  fieldSnapshots.docs.forEach(field => {
    if (field.data().photos !== undefined) {
      field.data().photos.forEach(photo => {
        photos.push(photo.url);
      })
    } 
  });

  if (photos.length > maxFieldPhotosToShow) {
    return shuffle(photos.slice(0, maxFieldPhotosToShow - 1));
  }

  return shuffle(photos);
}

function ShowField() {
  let params = useParams();
  const [searchTerm, setSearchTerm] = useState();

  const [fields, setFields] = useState([]);
  const [photos, setPhotos] = useState([])

  const [fieldSnapshot, fieldLoading, fieldError] = useDocument(
    doc(
      getFirestore(firebaseApp),
      "fields",
      params.fieldID
    )
  );

  useEffect(() => {
    if (fieldSnapshot?.data() !== undefined) {
      const field = fieldSnapshot.data();
      document.title = `${field.group_name} - Field Facts: Baseball`

      const db = getFirestore(firebaseApp);
      const collectionRef = collection(db, "fields")
      const q = query(collectionRef, where("group_name", "==", field.group_name), orderBy("field_name_number"));

      const analytics = getAnalytics(firebaseApp);
      const content = {
        content_type: 'field',
        content_id: fieldSnapshot.id,
        items: [{ name: `${field.group_name} (${field.field_name_number})` }]
      };
      logEvent(analytics, 'select_content', content);
      console.log(`Logging content view: ${JSON.stringify(content)}`);

      getDocs(q).then(qq => {
        console.log("Finished fetching fields: ", qq.docs.map(q => q.data()))
        setFields(qq);
        setPhotos(photosForFieldSnapshots(qq));
      });
    }

  }, [fieldSnapshot]);

  if (fieldLoading) {
    return <div className="loading">
      <BallTriangle
          heigth="100"
          width="100"
          color="#00704d"
          ariaLabel="loading-indicator"
          />
      </div>;
  }

  if (fieldError !== undefined) {
    return <div className="container-lg mt-5">
      <p>There was a problem fetching the field you requested. If refreshing does not solve the problem, please contact <a href="mailto:support@fieldfactsbaseball.com">support</a>.</p>
      <p><a href="/">Go Back</a></p>
    </div>
  }

  if (fieldSnapshot?.data() === undefined) {
    return <div className="container-lg mt-5">
      <p>There was a problem fetching the field you requested. If refreshing does not solve the problem, please contact <a href="mailto:support@fieldfactsbaseball.com">support</a>.</p>
      <p><a href="/">Go Back</a></p>
    </div>
  }

  return (
    <>
      <div className="container-lg">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} navigateOnChange />
      </div>
        <div className="mt-3">
          <PhotoHeader photoURLs={photos} />
        </div>
        <div className="container-lg">
        <FieldDetailHeader fieldSnapshot={fieldSnapshot} />
        {fields?.docs === undefined && <p>Loading...</p>}
        {fields?.docs && fields.docs.map(f => <Field key={f.id} fieldSnapshot={f}/>)}
        </div>
    </>
  );
}

const FieldDetailHeader = ({fieldSnapshot}) => {
  const field = fieldSnapshot.data();

  return (
    <div className="mt-5 field-detail">
      <div className="field-left">
        <h2><strong>{field.group_name}</strong></h2>
        <p>{field.displayable_address}</p>
      </div>
      <div className="field-right">

      </div>
    </div>
  );
}

export default ShowField;
