import {InfieldQuestions} from '../models/question'
import { FieldItem } from './FieldItem';
export const InfieldDetails = ({field}) => {
    return (
        <ul>
            {/* {JSON.stringify(field)} */}
            {InfieldQuestions().map(q => <FieldItem key={q.uuid} field={field} question={q} />)}
        </ul>
    );
}
