import "../css/Reviews.css"
import StyledRating from '@mui/material/Rating'
import { LinearProgress } from "@mui/material";
import { Review } from "./Review";

const averageReviewForField = (field, groupKey) => {
    const reviews = field.reviews.map(r => r[groupKey])
    if (field.reviews.length > 1) {
        return reviews.reduce((a, b) => a + b) / reviews.length;
    } else {
        return reviews[0];
    }
}

export const Reviews = ({field}) => {
    return (
        <>
            <div className="reviews">
                <div className="review-groups">
                    <ReviewGroup field={field} title="Overall" groupKey="overall" />
                    <ReviewGroup field={field} title="Infield" groupKey="infield" />
                    <ReviewGroup field={field} title="Outfield" groupKey="outfield" />
                    <ReviewGroup field={field} title="Surrounding Area" groupKey="surrounding" />
                </div>
                {field.reviews.map(review => <Review key={`${review.reviewer_email}-${review.date}`} review={review} />)}
            </div>
        </>
    );
}

const totalReviewsForStarCount = (reviews, starCount, groupKey) => {
    let total = 0;

    for (let i = 0; i < reviews.length; i++) {
        if (reviews[i][groupKey] === starCount) {
            total += 1;
        }
    }

    return total;
}

const ReviewBreakdown = ({field, groupKey}) => {
    return (
        <div className="review-breakdown">
            <>
            {[5,4,3,2,1].map(v =>
                <div key={v} className="review-breakdown-row">
                    <p className="star-count">{v} stars</p>
                    <LinearProgress className="progress" variant="determinate" value={totalReviewsForStarCount(field.reviews, v, groupKey) / field.reviews.length * 100.0} color="secondary" />
                    <p>{totalReviewsForStarCount(field.reviews, v, groupKey)}</p>
                </div>
            )}
            </>
        </div>
    );
}

const ReviewGroup = ({field, title, groupKey}) => {
    const ratingForGroup = averageReviewForField(field, groupKey);
    return (
            <div className="review-group">
                <p className="review-group-title">{title}</p>
                <StyledRating
                    className="review-group-overall-rating"
                    name="field-rating"
                    defaultValue={ratingForGroup}
                    value={ratingForGroup}
                    precision={0.5}
                    icon={<img alt="Rating Green" src="/rating-green.svg" />}
                    emptyIcon={<img alt="Rating White" src="/rating-white.png" />}
                    readOnly
                />
                <ReviewBreakdown field={field} groupKey={groupKey} />
            </div>
    )
}
