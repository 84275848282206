function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  

export class Question {
    constructor(options) {
        this.uuid = uuidv4();
        this.key = options.key;
        this.title = options.title;
        this.type = options.type;
        this.indented = options.indented;
        this.doubleIndented = options.doubleIndented;
        this.options = options.options;
        this.dependentOnKey = options.dependentOnKey;
        this.header = options.header;
        this.value = options.value;
    }

    cssClassName() {
        if (this.doubleIndented) {
            return "double-indented";
        }
        if (this.indented) {
            return "indented";
        }
        return "";
    }

    displayableValue(field) {
        const value = field[this.key];

        if (typeof(value) === 'boolean') {
            if (value === true) {
                return "Yes";
            } else {
                return "No";
            }
        }

        
        if (this.type === "toggle" && value === undefined) {
            return "No"
        }

        if (this.type === "other" && value === undefined) {
            return "Not Answered"
        }

        if (this.type === "dollar" && value === undefined) {
            return "Not Answered"
        }

        return value;
    }
}



export const InfieldQuestions = () => {
    return [
        new Question({key: "backstop", title: "Backstop", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),

        new Question({key: "backstop_distance_home_base", title: "Distance from home base", type: "select", value: undefined, options: ["Short", "Medium", "Far"], indented: true, dependentOnKey: "backstop"}),
        
        new Question({key: "backstop_height", title: "Height", type: "select", value: undefined, options: ["Low", "High"], indented: true, dependentOnKey: "backstop"}),
        
        new Question({key: "backstop_overhang", title: "Overhang", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "backstop"}),
        
        new Question({key: "pitchers_mound", title: "Pitcher's Mound", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "pitchers_mount_raised", title: "Raised", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "pitchers_mound"}),
        
        new Question({key: "pitchers_mount_dirt", title: "Dirt", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "pitchers_mound"}),
        
        new Question({key: "pitchers_mount_artificial_turf", title: "Artificial Turf", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "pitchers_mound"}),
        
        new Question({key: "infield_terrain", title: "Infield Terrain", type: "select", value: undefined, options: ["Dirt", "Grass", "Gravel", "Turf"], indented: false, dependentOnKey: undefined}),
        
        new Question({key: "infield_well_groomed", title: "Well Groomed", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "infield_fast_moving_balls", title: "Fast Moving Balls", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "infield_prone_bad_bounces", title: "Prone to Bad Bounces", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "dugouts", title: "Dugouts", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "dugouts_covered", title: "Covered", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "dugouts"}),
        
        new Question({key: "dugouts_seats", title: "Seats", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "dugouts"}),
        
        new Question({key: "dugouts_batter_warm_up_space", title: "Batter Warm Up Space", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "dugouts"}),
        
        new Question({key: "dugouts_bat_racks", title: "Bat Racks", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "dugouts"}),
        
        new Question({key: "dugouts_baseball_helmet_holders", title: "Baseball Helmet Holders", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "dugouts"}),
    ]
}

export const OutfieldQuestions = () => {
    return [
        new Question({key: "fence", title: "Fence", type: "select", value: undefined, options: ["Permanent", "Removable", "None"], indented: false, dependentOnKey: undefined}),
            
        new Question({key: "fence_distance_available", title: "Distance Available", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "fence"}),
        
        new Question({key: "fence_left_field_distance", title: "Left Field Distance (ft)", type: "number", value: undefined, options: undefined, indented: true, dependentOnKey: "fence_distance_available"}),
        
        new Question({key: "fence_center_field_distance", title: "Center Field Distance (ft)", type: "number", value: undefined, options: undefined, indented: true, dependentOnKey: "fence_distance_available"}),
        
        new Question({key: "fence_right_field_distance", title: "Right Field Distance (ft)", type: "number", value: undefined, options: undefined, indented: true, dependentOnKey: "fence_distance_available"}),
        
        new Question({key: "outfield_terrain", title: "Outfield Terrain", type: "select", value: undefined, options: ["Dirt", "Grass", "Gravel", "Turf"], indented: false, dependentOnKey: undefined}),
        
        new Question({key: "outfield_well_groomed", title: "Well Groomed", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "outfield_fast_moving_balls", title: "Fast Moving Balls", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "outfield_prone_bad_bounces", title: "Prone to Bad Bounces", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "lights", title: "Lights", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "score_boards", title: "Score Boards", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "stands", title: "Stands", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "bleachers_in_shade", title: "Bleachers in Shade", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "stands"}),
        
        new Question({key: "bring_your_own_chair", title: "Bring Your Own Chair", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
    ]
}

export const SurroundingAreaQuestions = () => {
    return [
        new Question({key: "concession_stands", title: "Concession Stands", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
            
        new Question({key: "candy", title: "Candy", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "concession_stands"}),
        
        new Question({key: "ice_cream", title: "Ice Cream", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "concession_stands"}),
        
        new Question({key: "fresh_cooked_food", title: "Fresh Cooked Food", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "concession_stands"}),
        
        new Question({key: "chips", title: "Chips", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "concession_stands"}),
        
        new Question({key: "other", title: "Other", type: "other", value: undefined, options: undefined, indented: true, dependentOnKey: "concession_stands"}), // TODO: Investigate OTHER
        
        new Question({key: "amenities", title: "Amenities", type: "empty", value: undefined, options: undefined, indented: false, dependentOnKey: undefined, header: true}), // TODO: Implement header styling in CSS
        
        new Question({key: "park_fee", title: "Park Fee", type: "dollar", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "bathrooms", title: "Bathrooms", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "porta_potties", title: "Porta Potties", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "bathrooms", doubleIndented: true}),
        
        new Question({key: "indoor_bathrooms", title: "Indoor Bathrooms", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "bathrooms", doubleIndented: true}),
        
        new Question({key: "overall_facility_bathroom_rating", title: "Overall Facility Bathroom Rating", type: "select", value: undefined, options: ["Poor", "Good", "Excellent"], indented: true, dependentOnKey: "bathrooms", doubleIndented: true}),
        
        new Question({key: "parking", title: "Parking", type: "empty", value: undefined, options: undefined, indented: false, dependentOnKey: undefined, header: true}),
        
        new Question({key: "parking_fee", title: "Parking Fee ($)", type: "dollar", value: undefined, options: undefined, indented: true, dependentOnKey: undefined}),
        
        new Question({key: "parking_distance_from_fields", title: "Distance from Fields", type: "select", value: undefined, options: ["Far", "Moderate", "Close"], indented: true, dependentOnKey: undefined}),
        
        new Question({key: "coolers_allowed", title: "Coolers Allowed", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "pet_friendly", title: "Pet Friendly", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "batting_cages", title: "Batting Cages", type: "toggle", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
        
        new Question({key: "pitching_machines", title: "Pitching Machines", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "batting_cages"}),
        
        new Question({key: "self_pitch", title: "Self Pitch", type: "toggle", value: undefined, options: undefined, indented: true, dependentOnKey: "batting_cages"}),
        
        new Question({key: "number_of_cages", title: "Number of Cages", type: "number", value: undefined, options: undefined, indented: true, dependentOnKey: "batting_cages"}),
        
        new Question({key: "number_surrounding_fields", title: "Number of Surrounding Fields", type: "number", value: undefined, options: undefined, indented: false, dependentOnKey: undefined}),
    ]
}