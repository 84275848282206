import '../css/Field.css';
import { CollapseableArea } from './CollapseableArea';
import { InfieldDetails } from './InfieldDetails';
import { OutfieldDetails } from './OutfieldDetails';
import PhotoHeader from './PhotoHeader';
import { Reviews } from './Reviews';
import { SurroundingAreaDetails } from './SurroundingAreaDetails';
import StyledRating from '@mui/material/Rating'

const fieldName = (field) => {
    if (parseInt(field.field_name_number) || field.field_name_number.length === 1) {
        return `Field ${field.field_name_number}`
    }
    return field.field_name_number;
}

const averageOverallReviewForField = (field) => {
    const overallReviews = field.reviews.map(r => r.overall)
    if (field.reviews.length > 1) {
        return overallReviews.reduce((a, b) => a + b) / overallReviews.length;
    } else {
        return overallReviews[0];
    }
}

export const Field = ({fieldSnapshot}) => {
    const field = fieldSnapshot.data();
    const photos = field.photos.map(p => p.url);
    const avgOverallReview = averageOverallReviewForField(field);

    const reviewCount = () => {
        if (field.reviews) {
            if (field.reviews.length > 1) {
                return `${field.reviews.length} reviews`
            } else {
                return `${field.reviews.length} review`
            }
        }
        return 0;
    }

    return (
        <div className="single-field-detail">
            <h2>{fieldName(field)}</h2>
            <div className="rating-group">
                {field?.reviews?.length > 0 && 
                <StyledRating
                    className="rating"
                    name="field-rating"
                    defaultValue={avgOverallReview}
                    precision={0.5}
                    icon={<img alt="Rating Green" src="/rating-green.svg" />}
                    emptyIcon={<img alt="Rating White" src="/rating-white.png" />}
                    readOnly
                />}
                <p>{reviewCount()}</p>
            </div>
            <PhotoHeader photoURLs={photos} />
            <div className="detail-area">
                <CollapseableArea title="Infield" expandedAreaComponent={<InfieldDetails field={field} />} />
                <CollapseableArea title="Outfield" expandedAreaComponent={<OutfieldDetails field={field} />} />
                <CollapseableArea title="Surrounding Area" expandedAreaComponent={<SurroundingAreaDetails field={field} />} />
                <div id={`reviews-${field.id}`}>
                    <CollapseableArea title="Reviews" expandedAreaComponent={<Reviews field={field} />} initiallyExpanded />
                </div>
            </div>
        </div>
    )
}