// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore"; 


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyBB4fPIBE2oIgmG_25b1jFvcZ_q5IZIs",
  authDomain: "fieldfacts-53c55.firebaseapp.com",
  databaseURL: "https://fieldfacts-53c55.firebaseio.com",
  projectId: "fieldfacts-53c55",
  storageBucket: "fieldfacts-53c55.appspot.com",
  messagingSenderId: "132752195672",
  appId: "1:132752195672:web:f9430ccba0f67ab5b80a2d",
  measurementId: "G-TJPRPRDNHH"
};

let firebaseApp;

// Initialize Firebase
const initializeFirebase = () => {
    firebaseApp = initializeApp(firebaseConfig);
    enableIndexedDbPersistence(getFirestore(firebaseApp));

    // Initialize analytics
    const analytics = getAnalytics(firebaseApp);

    logEvent(analytics, 'development_test');
    console.log("Logging page veiw: Landing Page");

}

export { firebaseApp, initializeFirebase }