import { collection, getDocsFromCache, getDocsFromServer, getFirestore, query } from 'firebase/firestore';
import { firebaseApp } from '../utilities/firebase';

const filterForSearch = (fieldSnapshot, searchTerm) => {
    const field = fieldSnapshot.data;
    if (field === undefined || field?.group_name === undefined || field?.address?.displayable_address === undefined) {
      console.log("Field is missing a value? ", field)
      return false;
    }

    const trimmedSearchTerm = searchTerm.trim().toUpperCase();
    return field.group_name.toUpperCase().includes(trimmedSearchTerm) || 
           field.address.displayable_address.toUpperCase().includes(trimmedSearchTerm)
  }
  
  const matchingFilteredGroupedDocs = (docs, searchTerm) => {
    const matchingDocs = docs.filter((fDoc => {
      return (filterForSearch(fDoc, searchTerm))
    }));
    
    let addresses = []
    const groupedDocs = matchingDocs.filter(d => {
      const data = d.data;
      let unique = true;
      if (addresses.includes(data.displayable_address)) {
        unique = false;
      }
      addresses.push(data.displayable_address)
      return unique;
    })
    return groupedDocs
  }

export async function getFields(searchTerm, setFieldsSnapshot, setFieldsError, setFieldsLoading) {
    try {
      const jsonInStorage = localStorage.getItem('ffb-fields')

      if (jsonInStorage) {
        console.log("Loading from local storage...")
        const fieldsFromStorage = JSON.parse(jsonInStorage);
        
        const fields = matchingFilteredGroupedDocs(fieldsFromStorage, searchTerm);
        setFieldsSnapshot({docs: fields});
        setFieldsError(undefined);
        setFieldsLoading(false);
        console.log("Loaded fields from storage");


      } else {
        console.log("Loading from Firestore...")
        const db = getFirestore(firebaseApp);
        const collectionRef = collection(db, "fields")
        const q = query(collectionRef);

        let querySnapshot = await getDocsFromCache(q);
        if (querySnapshot.docs.length === 0) {
            console.log("Nothing in cache. Fetching from server.")
            querySnapshot = await getDocsFromServer(q);
        } else {
            console.log("Fetched fields from cache")
        }

        const convertedSnapshotDocs = querySnapshot.docs.map(d => {
          return {id: d.id, data: d.data()}
        }) 
        const json = JSON.stringify(convertedSnapshotDocs);
        localStorage.setItem('ffb-fields', json)

        const fields = matchingFilteredGroupedDocs(convertedSnapshotDocs, searchTerm);
        setFieldsSnapshot({docs: fields})
        console.log("Loaded fields from Firestore")
      }
        
    } catch (error) {
      console.error(error);
      setFieldsError(error);
    }

    setFieldsLoading(false);
}
